.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
body {
  background-color: white;
}
/* poppup */

.popup {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-main {
  background-color: white;
  padding: 20px;
  width: 50%;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.popup-content {
  display: flex;
}

.popup h2 {
  font-size: 20px;
  margin: 0 0 10px 30px;
}

.popup p {
  margin-bottom: 20px;
}
.popup img {
  height: 220px;
  width: 250px;
}

.popup button {
  color: black;
  background-color: transparent;
  border: none;
  float: right;
  border-radius: 4px;
  cursor: pointer;
}
@media only screen and (max-width: 500px) {
  .popup-main {
    background-color: white;
    padding: 20px;
    width: 60%;

    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .popup img {
    height: 120px;
    width: 150px;
  }
  .popup-content {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 700px) {
  .popup-main {
    background-color: white;
    padding: 20px;
    width: 60%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .popup img {
    height: 120px;
    width: 150px;
  }
  .popup-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 950px) {
  .popup-main {
    background-color: white;
    padding: 20px;
    width: 60%;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  .popup img {
    height: 140px;
    width: 170px;
  }
  .popup-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}
/* Adjust the header height */
.ag-header {
  height: 39px;
  background-color: #f5f5f5; /* Adjust the height to your desired value */
}

/* Adjust the header font size */
.ag-header-cell-label {
  font-size: 16px;
  font-weight: lighter; /* Adjust the font size to your desired value */
}
/* App.css */
.excel-import-container {
  text-align: center;
  margin: 20px;
}

.multi-table-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.table-container {
  margin: 10px;
  border: 1px solid #ddd;
  padding: 10px;
  overflow: auto;
}
@media print {
  .printable-table {
    /* Adjust the styles for a print-friendly layout */
    overflow-x: visible; /* Ensure horizontal content is visible */
  }
}
